<script>
import {mapGetters} from "vuex";
import UiButton from "../../../ui/UiButton.vue";
import redirectMixin from "../../../../../plugins/redirectMixin";
import ReleaseCheckoutButton from "@/components/new/release/release-checkout-button.vue";

export default {
  name: "release-product-info",
  components: {UiButton, ReleaseCheckoutButton},
  mixins: [redirectMixin],
  props: {
    product: {
      type: Object,
      required: true,
    },
    release: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapGetters(['currency']),
    lnkProduct() {
      return {
        url: this.product.shop_url,
        productId: this.product.id,
      }
    },
    getProductPrice() {
      let currency = this.currency;

      let convertedPrice = this.product['price_' + currency.toLowerCase()];
      if (convertedPrice != false || convertedPrice != undefined)
        return this.$root.price(convertedPrice, currency);

      return '-';
    },

    getSalePrice() {
      let currency = this.currency;
      let convertedSalePrice = this.product['sale_price_' + currency.toLowerCase()];
      if (convertedSalePrice != false || convertedSalePrice != undefined)
        return this.$root.price(convertedSalePrice, currency);

      return '-';
    },
    hideCheckout() {
      // if (this.$route.query.show_checkout)
      //   return false;
      // return true;

      const country = (this.$store.state.location.code || 'US').toUpperCase();
      if (['US', 'JP', 'KR'].includes(country))
        return false;
      return true;
    },
  },
  methods: {

    discount(price, salePrice) {
      return Math.round(((price - salePrice) / price) * 100) + "%";
    },
  }
}
</script>

<template>
  <div class="flex flex-col p-4 lg:p-8 product-info">
    <h1 class="text-black text-base font-semibold">{{release.brand}} {{release.name}}</h1>
    <div class="flex flex-col w-full mt-2" v-if="product.id">
      <h2 class="text-gray-high text-xs line-through mb-1" v-if="product.sale_price">
        {{getProductPrice}}
      </h2>
      <div class="text-base font-semibold flex flex-row">
        <h2 class="text-danger mr-1" v-if="product.sale_price">{{discount(product.price, product.sale_price)}}</h2>
        <h2 class="text-black">{{product.sale_price ? getSalePrice : getProductPrice}}</h2>
      </div>
      <div class="text-xs text-black mt-3">
        <h4>From. {{product.shop.name}}</h4>
      </div>
      <div class="flex flex-col mt-3 w-full">
        <UiButton :to="localePath({name: 'new-go',query: goProductQuery })" class="w-full" color="primary" size="lg" height="lg" font-size="sm" target="_blank" block underline :bold="false">{{$t('label.buyDirect')}}</UiButton>
      </div>
      <div class="product-button-divide mt-4" v-show="!hideCheckout">
        <div class="product-button-divide-or">OR</div>
        <div class="product-button-divide-description">
          <div>
            {{$t('text.productPage.shippingFeeOfThisProduct')}} : <span class="no-pointer">{{(product.exchange && product.exchange.shippingFee) ? product.exchange.shippingFeeDisplay : 'Free'}}</span>
          </div>
          <div v-html="$t('text.productPage.directDeliveryByShopMessage', {shopName: product.shop.name})" @click="() => goToDirect()">
          </div>
        </div>
      </div>

      <release-checkout-button :product="product" v-show="!hideCheckout"/>
    </div>

  </div>
</template>

<style lang="scss" scoped>
.product-info {
  flex: 1;
  margin-right: 40px;
  .product-related-more {
    display: none;
  }
  .product-price-only-sale {
    display: flex;
    flex-direction: row;
    margin-bottom: 16px;
    height: 28px;
    .product-price {
      font-size: 20px;
      font-weight: normal;
      line-height: 30px;
      text-decoration: line-through;
      color: #191919;
    }
    .product-sale-price {
      font-size: 28px;
      font-weight: 800;
      line-height: 28px;
      color: #db3d46;
      margin-right: 6px;
    }
  }
  .product-info-discount {
    display: flex;
    span{
      display: block;
      font-size: 14px;
      font-weight: 600;
      line-height: 24px;
      height: 24px;
      color: #db3d46;
      margin-bottom: 8px;
      padding: 0 8px;
      border-radius: 4px;
      background-color: #ffceba;
    }
  }
  .brand-name {
    font-size: 18px;
    font-weight: 800;
    line-height: 18px;
    margin-bottom: 8px;
    color: #191919;
  }
  .product-name {
    font-size: 28px;
    font-weight: 700;
    line-height: 28px;
    margin-bottom: 8px;
  }
  .product-price {
    font-size: 28px;
    font-weight: 700;
    line-height: 28px;
    margin-bottom: 8px;
  }
  .product-sale-price {
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;
    margin-bottom: 16px;
  }
  .product-sku {
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    color: #191919;
    margin-bottom: 18px;
  }
  .product-from-shop{
    font-size: 14px;
    font-weight: normal;
    line-height: 14px;
    color: #8999b1;
    margin-bottom: 12px;
    span {
      font-weight: normal;
      color: #191919;
    }
  }

  .product-buy-buttons {
    display: flex;
    flex-direction: row;
    .product-related-button {
      flex: 1;
      margin-right: 8px;
    }
    .product-buy-direct-button {
      flex: 1;
    }
  }

  .product-coupon-area{
    display: flex;
    flex-direction: row;
    margin-bottom: 12px;

    .product-coupon-left-area{
      display: none;
    }

    .product-coupon-right-area{
      display: none;
    }


    &.show-area{
      margin-top: 10px;
      .product-coupon-left-area{
        display: flex;
        flex: 1;
        margin-right: 8px;
      }
      .product-coupon-right-area{
        flex: 1;
        display: flex;
        justify-content: flex-end;
        position: relative;
        cursor: pointer;
        .product-coupon-right-area-flag {
          background-color: #23d1d8;
          border-radius: 8px;
          color: #fff;
          display: block;
          font-weight: 700;
          padding: 8px 16px;
          text-align: center;
          width: 100%;
          font-size: 12px;

          &::after {
            border-color: #23d1d8 transparent;
            border-style: solid;
            border-width: 0 10px 10px;
            content: "";
            display: block;
            left: calc(50% - 10px);
            position: absolute;
            top: -8.5px;
            width: 0;
          }
        }

      }
    }
  }

  .product-button-divide{
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
    .product-button-divide-or{
      font-size: 12px;
      font-weight: normal;
      color: #6c7781;
      margin-bottom: 8px;
      flex: 1;
      text-align: center;
    }
    .product-button-divide-description{
      font-size: 12px;
      font-weight: normal;
      line-height: 22px;
      color: #6c7781;
      flex: 1;
      span{
        font-weight: 700;
        color: #6c7781;
        text-decoration: underline;
      }
    }
  }
  .product-memo {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: #a0acc1;
    margin-bottom: 24px;
    span {
      font-weight: 700;
      color: #191919;
    }
    a {
      color: #191919;
      font-weight: 700;

      &:hover {
        color: #191919;
      }

      &:visited {
        color: #191919;
      }

      &:active {
        color: #191919;
      }

      &:focus {
        color: #191919;
      }
    }
  }


  /** mobile **/
  @media (max-width: 1023px) {
    margin-right: 16px;
    margin-top: 0;

    .product-related-more {
      display: flex;
      border-top: 1.5px solid #dfe6f0;
      border-bottom: 1.5px solid #dfe6f0;
      padding: 16px 10px;
      margin-bottom: 16px;
      margin-top: 0;
      align-items: center;

      .product-related-more-icon {
        width: 24px;
        height: 24px;
        margin-right: 8px;
      }

      .product-related-more-text {
        flex: 1;
        font-size: 14px;
        font-weight: 800;
        color: #191919;
        margin-right: 8px;
      }

      .product-related-more-arrow {
        width: 7px;
        height: 11px;
      }
    }
  }

  .product-info-shipping-support-countries{
    padding: 0 8px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: #191919;
    margin-bottom: 24px;
  }
  /** mobile **/
  @media (max-width: 767px) {
    margin-right: 0;

    .product-related-more {
      margin-top: 16px;
    }

    .product-buy-buttons {
      flex-direction: column;
      .product-related-button {
        display: none;
      }
    }
  }
}
</style>