<template>
  <div class="checkout-wrap">
    <div class="loading-overlay" id="checkout-loading">
      <div class="loading-spinner"></div>
    </div>
    <div class="checkout-container h-full" v-show="!(calculate && calculate.products && calculate.products.length > 0)">
      <div class="checkout-header">
        <h1 class="checkout-header-title">Checkout is Temporarily Unavailable</h1>
        <p class="checkout-header-subtitle mt-4">We apologize for the inconvenience. Our checkout process is undergoing maintenance and will be back shortly. Thank you for your patience.</p>
        <u-button class="checkout-order-btn mt-6" :click="goBack" :block="false">
          Go back to item
        </u-button>
      </div>
    </div>
    <div class="checkout-container" v-show="(calculate && calculate.products && calculate.products.length > 0)">
      <div class="checkout-header">
        <h1 class="checkout-header-title">
          {{ $t('text.checkout.conciergeTitle') }}
        </h1>
        <p class="checkout-header-subtitle">
          {{ $t('text.checkout.conciergeSubtitle') }}
        </p>
      </div>

      <div class="checkout-info-wrap">
        <div class="checkout-info-container">
          <div class="checkout-info-group">
            <div class="checkout-info-item">
              <div class="checkout-info-item-title">
              <span class="checkout-info-item-title-icon">
                <img src="~/assets/icons/ui/shopping-bag.svg" alt="order"/>
              </span>
                <span class="checkout-info-item-title-text">
                {{ $t('text.checkout.paymentInfo') }}
              </span>
              </div>
              <div class="checkout-info-item-content">
                <p class="checkout-info-item-content-text">
                  {{ $t('text.checkout.paymentInfoSubtitle') }}<br/>
                  <a target="_blank" :href="localePath({name: 'terms' })">[{{ $t('label.terms') }}]</a>
                  <a target="_blank" :href="localePath({name: 'return-policy' })">[{{ $t('label.returnsPolicy') }}]</a>
                </p>
              </div>
            </div>

            <div class="checkout-info-item">
              <div class="checkout-info-item-title">
              <span class="checkout-info-item-title-icon">
                <img src="~/assets/icons/ui/user.svg" alt="buyers"/>
              </span>
                <span class="checkout-info-item-title-text">
                {{ $t('text.checkout.buyerInfo') }}
              </span>
              </div>

              <div class="checkout-info-item-content">
                <div class="checkout-info-item-form">
                  <div class="checkout-info-input-item pc-half-width">
                    <div class="checkout-info-input-item-label">
                      {{ $t('text.checkout.firstName') }}
                    </div>
                    <div class="checkout-info-input-item-input">
                      <b-form-input v-model="checkout.buyer.firstName" :state="inputErrors.buyer.firstName" :readonly="!!order"></b-form-input>
                      <b-form-invalid-feedback :state="inputErrors.buyer.firstName">
                        {{ $t('text.checkout.isRequired', {field: $t('text.checkout.firstName')}) }}
                      </b-form-invalid-feedback>
                    </div>
                  </div>

                  <div class="checkout-info-input-item pc-half-width">
                    <div class="checkout-info-input-item-label">
                      {{ $t('text.checkout.lastName') }}
                    </div>
                    <div class="checkout-info-input-item-input">
                      <b-form-input v-model="checkout.buyer.lastName" :state="inputErrors.buyer.lastName" :readonly="!!order"></b-form-input>
                      <b-form-invalid-feedback :state="inputErrors.buyer.lastName">
                        {{ $t('text.checkout.isRequired', {field: $t('text.checkout.lastName')}) }}
                      </b-form-invalid-feedback>
                    </div>
                  </div>

                  <div class="checkout-info-input-item">
                    <div class="checkout-info-input-item-label">
                      {{ $t('text.checkout.phone') }}
                    </div>
                    <div class="checkout-info-input-item-input">
                      <b-form-input v-model="checkout.buyer.phone" :state="inputErrors.buyer.phone" :readonly="!!order"></b-form-input>
                      <b-form-invalid-feedback :state="inputErrors.buyer.phone">
                        {{ $t('text.checkout.isRequired', {field: $t('text.checkout.phone')}) }}
                      </b-form-invalid-feedback>
                    </div>
                  </div>

                  <div class="checkout-info-input-item">
                    <div class="checkout-info-input-item-label">
                      {{ $t('text.checkout.email') }}
                    </div>
                    <div class="checkout-info-input-item-input">
                      <b-form-input v-model="checkout.buyer.email" :state="inputErrors.buyer.email" :readonly="!!order"></b-form-input>
                      <b-form-invalid-feedback :state="inputErrors.buyer.email">
                        {{ $t('text.checkout.isRequired', {field: $t('text.checkout.email')}) }}
                      </b-form-invalid-feedback>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="checkout-info-item">
              <div class="checkout-info-item-title">
              <span class="checkout-info-item-title-icon">
                <img src="~/assets/icons/ui/truck.svg" alt="shipping"/>
              </span>
                <span class="checkout-info-item-title-text">
                {{ $t('text.checkout.shippingInfo') }}
              </span>
                <div class="checkout-info-item-title-action">
                  <div class="u-checkbox" :class="{checked: syncAddress}" @click="() => {
                    if(!order){
                      syncAddress = !syncAddress;
                    }
                  }">
                    <div class="u-checkbox-icon">
                      <img src="~/assets/icons/ui/check-icon-white.svg" alt="check" class="u-checkbox-icon-checked">
                    </div>
                  </div>
                  <label for="same-with-buyer">{{ $t('text.checkout.sameWithBuyer') }}</label>
                </div>
              </div>

              <div class="checkout-info-item-content">
                <div class="checkout-info-item-form">
                  <div class="checkout-info-input-item pc-half-width">
                    <div class="checkout-info-input-item-label">
                      {{ $t('text.checkout.firstName') }}
                    </div>
                    <div class="checkout-info-input-item-input">
                      <b-form-input v-model="checkout.recipient.firstName" :state="inputErrors.recipient.firstName" :readonly="!!order"></b-form-input>
                      <b-form-invalid-feedback :state="inputErrors.recipient.firstName">
                        {{ $t('text.checkout.isRequired', {field: $t('text.checkout.firstName')}) }}
                      </b-form-invalid-feedback>
                    </div>
                  </div>

                  <div class="checkout-info-input-item pc-half-width">
                    <div class="checkout-info-input-item-label">
                      {{ $t('text.checkout.lastName') }}
                    </div>
                    <div class="checkout-info-input-item-input">
                      <b-form-input v-model="checkout.recipient.lastName" :state="inputErrors.recipient.lastName" :readonly="!!order"></b-form-input>
                      <b-form-invalid-feedback :state="inputErrors.recipient.lastName">
                        {{ $t('text.checkout.isRequired', {field: $t('text.checkout.lastName')}) }}
                      </b-form-invalid-feedback>
                    </div>
                  </div>

                  <div class="checkout-info-input-item">
                    <div class="checkout-info-input-item-label">
                      {{ $t('text.checkout.phone') }}
                    </div>
                    <div class="checkout-info-input-item-input">
                      <b-form-input v-model="checkout.recipient.phone" :state="inputErrors.recipient.phone" :readonly="!!order"></b-form-input>
                      <b-form-invalid-feedback :state="inputErrors.recipient.phone">
                        {{ $t('text.checkout.isRequired', {field: $t('text.checkout.phone')}) }}
                      </b-form-invalid-feedback>
                    </div>
                  </div>

                  <div class="checkout-info-input-item">
                    <div class="checkout-info-input-item-label">
                      {{ $t('text.checkout.country') }}
                    </div>
                    <div class="checkout-info-input-item-input">
                      <select v-model="checkout.recipient.country" @change="reFetch" :disabled="order || forceCountry">
                        <option v-for="country in countries" :value="country.code" :key="country.code">{{country.name}}</option>
                      </select>
                    </div>
                  </div>

                  <div class="checkout-info-input-item">
                    <div class="checkout-info-input-item-label">
                      {{ $t('text.checkout.zipCode') }}
                    </div>
                    <div class="checkout-info-input-item-input">
                      <b-form-input v-model="checkout.recipient.postalCode" :state="inputErrors.recipient.postalCode" :readonly="!!order"></b-form-input>
                      <b-form-invalid-feedback :state="inputErrors.recipient.postalCode">
                        {{ $t('text.checkout.isRequired', {field: $t('text.checkout.zipCode')}) }}
                      </b-form-invalid-feedback>
                    </div>
                  </div>

                  <div class="checkout-info-input-item">
                    <div class="checkout-info-input-item-label">
                      {{ $t('text.checkout.address1') }}
                    </div>
                    <div class="checkout-info-input-item-input">
                      <b-form-input v-model="checkout.recipient.address1" :state="inputErrors.recipient.address1" :readonly="!!order"></b-form-input>
                      <b-form-invalid-feedback :state="inputErrors.recipient.address1">
                        {{ $t('text.checkout.isRequired', {field: $t('text.checkout.address1')}) }}
                      </b-form-invalid-feedback>
                    </div>
                  </div>

                  <div class="checkout-info-input-item">
                    <div class="checkout-info-input-item-label">
                      {{ $t('text.checkout.address2') }}
                    </div>
                    <div class="checkout-info-input-item-input">
                      <b-form-input v-model="checkout.recipient.address2" :state="inputErrors.recipient.address2" :readonly="!!order"></b-form-input>
                      <b-form-invalid-feedback :state="inputErrors.recipient.address2">
                        {{ $t('text.checkout.isRequired', {field: $t('text.checkout.address2')}) }}
                      </b-form-invalid-feedback>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div class="checkout-info-group">

            <div class="checkout-info-item">
              <div class="checkout-info-item-title">
              <span class="checkout-info-item-title-icon">
                <img src="~/assets/icons/ui/store2.svg" alt="item"/>
              </span>
                <span class="checkout-info-item-title-text">
                {{ $t('text.checkout.itemInfo') }}
              </span>
              </div>
              <div class="checkout-info-item-content">
                <div class="checkout-item-info-wrap">
                  <div class="checkout-item-info-delivery">
                    <div class="checkout-item-info-delivery-title">
                      {{ $t('text.checkout.deliveryPeriod') }} : {{ $t('text.checkout.deliveryPeriodValue') }}
                    </div>
                    <div class="checkout-item-info-delivery-price">
                      {{calculate.shippingFee ? priceFormat(calculate.shippingFee, calculate.currencyCode) : 'Free Shipping'}}
                    </div>
                  </div>
                  <div class="checkout-item-info-soldout" v-if="!calculate.isAllValid">
                    Out of Stock
                  </div>
                  <div class="checkout-item-info-products">
                    <div class="checkout-item-info-product" v-for="product in calculate.products" :key="product.id">
                      <div class="checkout-item-info-product-left">
                        <div class="checkout-item-info-product-info">
                          <div><b>{{product.catalog.brand.name}}</b></div>
                          <div>{{product.name}}</div>
                          <div v-if="product.sku">{{product.sku.sku}}</div>
                          <div v-if="option">{{option}}</div>

                          <div class="checkout-item-info-product-price">
                            {{product.exchange.salePriceDisplay}}
                          </div>
                        </div>
                        <div class="checkout-item-info-product-image">
                          <img :src="product.coverImageUrl" :alt="product.name">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="checkout-item-info-description">
                    <p class="checkout-item-info-description-text"
                       v-html="$t('text.checkout.itemInfoDescription', {shopName: shopName, shopLink: localePath({name: 'new-go',query: goProductQuery })})">
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="checkout-info-item">
              <div class="checkout-info-item-title">
              <span class="checkout-info-item-title-icon">
                <img src="~/assets/icons/ui/credit-card.svg" alt="payment"/>
              </span>
                <span class="checkout-info-item-title-text">
                {{ $t('text.checkout.paymentSummary') }}
              </span>
              </div>
              <div class="checkout-info-item-content">
                <div class="checkout-payment-summary">
                  <div class="checkout-payment-summary-table">
                    <div class="checkout-payment-summary-table-row">
                      <div class="checkout-payment-summary-table-cell">
                        {{ $t('text.checkout.itemTotals') }}
                      </div>
                      <div class="checkout-payment-summary-table-cell tright">
                        {{priceFormat(calculate.price, calculate.currencyCode)}}
                      </div>
                    </div>
                    <div class="checkout-payment-summary-table-row">
                      <div class="checkout-payment-summary-table-cell">
                        {{ $t('text.checkout.shippingFee') }}
                      </div>
                      <div class="checkout-payment-summary-table-cell tright">
                        {{calculate.shippingFee ? priceFormat(calculate.shippingFee, calculate.currencyCode) : 'Free Shipping'}}
                      </div>
                    </div>
                    <div class="checkout-payment-summary-table-row" v-if="calculate.tax">
                      <div class="checkout-payment-summary-table-cell">
                        {{ $t('text.checkout.taxAndDuties') }}
                      </div>
                      <div class="checkout-payment-summary-table-cell tright tax-duties">
                        {{priceFormat(calculate.tax, calculate.currencyCode)}}
                      </div>
                    </div>
                    <div class="checkout-payment-summary-table-row">
                      <div class="checkout-payment-summary-table-cell">
                        <b>{{ $t('text.checkout.orderTotal') }}</b>
                      </div>
                      <div class="checkout-payment-summary-table-cell tright price-final">
                        {{priceFormat(calculate.amount, calculate.currencyCode)}}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

        <div class="checkout-order-btn-wrap">
          <div class="checkout-order-btn-title">
            {{ $t('text.checkout.totalIncludingDelivery') }}
          </div>
          <div class="checkout-order-btn-price">
            {{priceFormat(calculate.amount, calculate.currencyCode)}}
          </div>
          <div class="checkout-order-btn-area">
            <u-button v-show="!order" class="checkout-order-btn" :click="onCheckout" :block="false">
              {{ $t('text.checkout.saveAndContinue') }}
            </u-button>
          </div>
          <div v-show="order" class="checkout-order-paypal">
            <div class="portone-ui-container review-form topmargin-xs" data-portone-ui-type="paypal-rt"></div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>


<style scoped lang="scss">
.checkout-wrap{
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  background-color: #f4f5f9;
  padding-bottom: 40px;
  .checkout-container.h-full {
    min-height: 90vh;
    padding-top:20vh;
  }
  .btn-goback {

  }
  .checkout-container{
    max-width: 1040px;
    margin: 0 auto;

    .checkout-header{
      text-align: center;
      padding: 40px 0;

      .checkout-header-title{
        font-size: 24px;
        font-weight: 800;
        text-align: center;
        color: #191919;
        //margin-bottom: 20px;
      }

      .checkout-header-subtitle{
        font-size: 14px;
        font-weight: normal;
        text-align: center;
        color: #191919;
        text-wrap: normal;
        word-break: keep-all;
      }
    }

    .checkout-info-wrap{
      display: flex;
      flex-direction: column;
      background: #fff;
      border-radius: 16px;
      padding: 36px 20px;

      .checkout-order-btn-wrap{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 20px;
        .checkout-order-btn-title{
          font-size: 14px;
          font-weight: normal;
          text-align: center;
          color: #191919;
        }
        .checkout-order-btn-price{
          font-size: 24px;
          font-weight: 800;
          text-align: center;
          color: #191919;
          margin-bottom: 20px;
        }
        .checkout-order-btn-area{
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;
          .checkout-order-btn{
            width: 100%;
            max-width: 300px;
            margin-bottom: 20px;
          }
        }
        .checkout-order-paypal{
          width: 100%;
          .portone-ui-container{
            width: 100%;
            max-width: 300px;
            margin: 0 auto;
          }
        }
      }
      .checkout-info-container{
        display: flex;
        flex-direction: row;
        flex: 1;
      }

      .checkout-info-group{
        flex: 1;
        padding: 0 20px;
        border-right: 1.5px solid #dfe6f0;

        &:last-child{
          border-right: none;
        }

        .checkout-info-item{
          margin-bottom: 20px;

          .checkout-info-item-title{
            display: flex;
            align-items: center;
            margin-bottom: 10px;

            .checkout-info-item-title-icon{
              margin-right: 10px;
              display: flex;
              align-items: center;
              img{
                width: auto;
                height: 20px;
              }
            }

            .checkout-info-item-title-text{
              font-size: 20px;
              font-weight: 800;
              text-align: left;
              color: #191919;
            }

            .checkout-info-item-title-action{
              margin-left: auto;
              display: flex;
              align-items: center;
              .u-checkbox{
                margin-right: 5px;
                width: 20px;
                height: 20px;
                border: 1px solid #dfe6f0;
                border-radius: 4px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                .u-checkbox-icon{
                  display: none;
                  img{
                    width: auto;
                    height: 8px;
                    margin-right: -2px;
                  }
                }

                &.checked{
                  background-color: #23d1d8;
                  border: none;
                  .u-checkbox-icon{
                    display: flex;
                  }
                }
              }
              label{
                font-size: 14px;
                font-weight: normal;
                text-align: left;
                color: #8999b1;
                margin-bottom: 1px;
              }
            }
          }

          .checkout-info-item-content{
            font-size: 14px;
            font-weight: normal;
            color: #191919;
            .checkout-info-item-content-text{
              text-align: left;
              text-wrap: normal;
              word-break: keep-all;
            }


            .checkout-payment-summary{
              .checkout-payment-summary-table{
                .checkout-payment-summary-table-row{
                  display: flex;
                  justify-content: space-between;
                  padding: 2px 0;
                  .checkout-payment-summary-table-cell{
                    font-size: 14px;
                    font-weight: normal;
                    color: #191919;
                    &.tright{
                      text-align: right;
                    }
                  }
                }
              }
            }
            .checkout-item-info-wrap{
              display: flex;
              flex-direction: column;

              .checkout-item-info-delivery{
                border-top: 1.5px solid #dfe6f0;
                padding-top: 10px;
                padding-bottom: 10px;
                display: flex;
                justify-content: space-between;
                .checkout-item-info-delivery-title{
                  font-size: 14px;
                  font-weight: 500;
                  color: #191919;
                }
                .checkout-item-info-delivery-price{
                  font-size: 14px;
                  font-weight: 500;
                  color: #191919;
                }
              }

              .checkout-item-info-soldout{
                border-top: 1.5px solid #dfe6f0;
                padding-top: 10px;
                padding-bottom: 10px;
                background-color: #dc3545;
                color: #fff;
                text-align: center;
                border-radius: 2px;
              }

              .checkout-item-info-products{
                border-top: 1.5px solid #dfe6f0;
                padding-top: 10px;
                padding-bottom: 10px;
                .checkout-item-info-product{
                  display: flex;
                  flex-direction: column;

                  .checkout-item-info-product-left{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    .checkout-item-info-product-info{
                      font-size: 14px;
                      font-weight: normal;
                      color: #191919;
                      div{
                      }


                      .checkout-item-info-product-price{
                        font-size: 14px;
                        font-weight: 800;
                        color: #191919;
                      }
                    }
                    .checkout-item-info-product-image{
                      margin-left: 20px;
                      width: 100px;
                      height: 100px;
                      img{
                        max-width: 100%;
                        max-height: 100%;
                        object-fit: contain;
                        border-radius: 8px;
                        aspect-ratio: 1 / 1;
                      }
                    }
                  }
                }
              }

              .checkout-item-info-description{
                border-top: 1.5px solid #dfe6f0;
                padding-top: 10px;
                padding-bottom: 10px;
                font-size: 14px;
                font-weight: 500;
                color: #191919;
                .checkout-item-info-description-text{
                  text-align: left;
                  text-wrap: normal;
                  word-break: keep-all;

                  a{
                    color: #191919;
                    font-weight: 700;
                    &:hover{
                      color: #191919;
                    }
                    &:visited{
                      color: #191919;
                    }
                    &:active{
                      color: #191919;
                    }
                    &:focus{
                      color: #191919;
                    }
                  }
                }
              }

            }


            .checkout-info-item-form{
              display: flex;
              flex-wrap: wrap;


              .checkout-info-input-item{
                margin-bottom: 20px;
                flex: 0 0 100%;
                .invalid-feedback{
                  padding: 0;
                }

                &.pc-half-width{
                  flex: 0 0 50%;
                  max-width: 50%;
                  padding-right: 20px;

                  &:nth-child(2n){
                    padding-right: 0;
                  }
                }

                .checkout-info-input-item-label{
                  font-size: 14px;
                  font-weight: normal;
                  margin-bottom: 10px;
                  text-align: left;
                  color: #8999b1;
                }

                .checkout-info-input-item-input{

                  select{
                    height: 48px;
                    width: 100%;
                    border: 1px solid #dfe6f0;
                    border-radius: 8px;
                    padding: 10px;
                    padding-left: 20px;
                    font-size: 14px;
                    font-weight: 500;
                    color: #191919;

                    /** reset select **/
                    margin: 0;
                    -webkit-box-sizing: border-box;
                    -moz-box-sizing: border-box;
                    box-sizing: border-box;
                    -webkit-appearance: none;
                    -moz-appearance: none;

                    background: url('data:image/svg+xml;utf8,<svg fill="black" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>') no-repeat right 10px top 50%;
                    background-size: 20px;

                    &:focus{
                      baground: url('data:image/svg+xml;utf8,<svg fill="black" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>') no-repeat right 10px top 50%;
                      background-size: 20px;
                    }
                  }

                  input{
                    height: 48px;
                    width: 100%;
                    border: 1px solid #dfe6f0;
                    border-radius: 8px;
                    padding: 10px;
                    padding-left: 20px;
                    font-size: 14px;
                    font-weight: 500;
                    color: #191919;
                  }
                }
              }
            }

          }
        }
      }
    }
  }


  /** mobile **/
  @media (max-width: 1024px){
    .checkout-header{
      padding: 20px !important;
      padding-top: 40px !important;
      .checkout-header-title{
        font-size: 20px;
      }
      .checkout-header-subtitle{
        font-size: 12px;
      }
    }
    .checkout-container {
      .checkout-info-wrap {
        background: transparent;
        border-radius: 0;
        padding: 20px;
        .checkout-info-container {
          flex-direction: column-reverse;
        }

        .checkout-info-group {
          padding: 0;
          border-right: none;

          .checkout-info-item {
            background: #fff;
            margin: 0;
            padding: 16px;
            margin-bottom: 10px;
            border-radius: 12px;


          }
        }
      }
    }


    .pc-half-width {
      flex: 0 0 100% !important;
      max-width: 100% !important;
      padding: 0 !important;
    }
  }

}


</style>


<script>
import SimpleHeader from "../../../components/SimpleHeader.vue";
import Breadcrumbs from "../../../components/Breadcrumbs.vue";
import shippingMixin from '../../../plugins/shippingMixin';
import productDetailsSchema from '../../../plugins/new-product-details-schema';
import {isEmpty, keys} from "lodash-core";
import redirectMixin from '../../../plugins/redirectMixin.js';
import {priceFormat} from "../../../lib/utils";
import UButton from "@/components/ui/button.vue";
import {objectToQueryString} from "@/lib/catalog-tools";
import axios from "axios";

const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
export default {
  name: "product_checkout",
  validate ({ params }) {
    return params.id;
  },
  mixins: [shippingMixin, redirectMixin],
  components: {UButton, SimpleHeader, Breadcrumbs },
  async asyncData({store, params, $axios, app, redirect, error, query})
  {
    try {
      const country = (store.state.location.code || 'US').toUpperCase();
      const currency = (store.state.currency || 'USD').toUpperCase();
      const language = (app.i18n.locale || 'en').toLowerCase();
      const requestData = {
        products: [
          {
            id: params.id,
            quantity: 1
          }
        ]
      };
      
      const url = process.env.newApiUrlSsr + (query.snk ? `/v1/orders/calculate-snk?p=${query.p}` : '/v1/orders/calculate');

      const {data} = await axios.post(url, requestData, {
        headers: {
          'Content-Type': 'application/json',
          'X-County-Code': country,
          'X-Currency-Code': currency,
          'X-Language-Code': language,
          'X-Client-Uuid': store.state.clientUid || null,
        }
      });

      if (data && data.data) {

        return {
          calculate: data.data,
          countryCode: country,
          currencyCode: currency,
          languageCode: language,
          clientUid: store.state.clientUid || null,
        };
      }

      return {
        // payment: {
        //   IMP_KEY: process.env.IMP_KEY,
        //   IMP_SHOP: process.env.IMP_SHOP,
        //   PAYPAL_ACCOUNT_ID: process.env.PAYPAL_ACCOUNT_ID,
        // },
      }
    }
    catch (e) {
      console.error(e);
      console.log('prod ', e.response ? e.response.status : e);
      redirect('/404');
    }
  },
  data() {
    return {
      countryCode: '',
      currencyCode: '',
      languageCode: '',
      clientUid: null,
      forceCountry: false,
      calculate: {
        products: [],
        isAllValid: true,
        price: 0,
        tax: 0,
        shippingFee: 0,
        amount: 0
      },
      order: null,
      valid: false,

      // payment: {
      //   IMP_KEY: null,
      //   IMP_SHOP: null,
      //   PAYPAL_ACCOUNT_ID: null,
      // },
      checkout: {
        buyer: {
          firstName: '',
          lastName: '',
          phone: '',
          email: '',
        },
        recipient: {
          firstName: '',
          lastName: '',
          phone: '',
          country: this.$store.state.location.code,
          address1: '',
          address2: '',
          postalCode: '',
        },
      },
      syncAddress: false,
      sizeType: 'US',
      option: '',
    }
  },
  created(){
    if(this.$store.state.auth.user){
      this.checkout.buyer.email = this.$store.state.auth.user.email || '';
      this.checkout.buyer.firstName = this.$store.state.auth.user.name || '';
      this.checkout.buyer.lastName = this.$store.state.auth.user.last_name || '';
    }

    this.forceCountry = this.$route.query.p ? true : false;

  },
  mounted() {
    this.option = this.$route.query.size ? atob(this.$route.query.size) : '';
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      // Scroll to the top of the page after the component has been loaded
      vm.$nextTick(() => {
        window.scrollTo(0, 0);
      });
    });
  },
  methods   : {
    goBack()
    {
      this.$router.go(-1)
    },
    priceFormat,
    async onCheckout(){
      // console.log(this.payment);

      this.valid = true;

      for(const k in this.inputErrors.buyer){
        if(!this.inputErrors.buyer[k]){
          return;
        }
      }

      for(const k in this.inputErrors.recipient){
        if(!this.inputErrors.recipient[k]){
          return;
        }
      }

      try{
        const requestData = {
          userId: this.$store.state.auth.user ? this.$store.state.auth.user.id : null,
          buyer: {
            firstName: this.checkout.buyer.firstName,
            lastName: this.checkout.buyer.lastName,
            email: this.checkout.buyer.email,
            phone: this.checkout.buyer.phone,
          },
          recipient: {
            firstName: this.checkout.recipient.firstName,
            lastName: this.checkout.recipient.lastName,
            phone: this.checkout.recipient.phone,
            country: this.checkout.recipient.country,
            address: this.checkout.recipient.address1,
            addressDetail: this.checkout.recipient.address2,
            postalCode: this.checkout.recipient.postalCode,
          },
          products: this.calculate.products.map((product) => ({
            id: product.id,
            quantity: product.quantity,
            option: this.option,
          })),
          currencyId: this.calculate.currencyId,
          countryId: this.calculate.countryId,
          additionalRequest: null
        };

        const url = process.env.newApiUrl + '/v1/orders';
        const { data } = await axios.post(url, requestData, {
          headers: {
            'Content-Type': 'application/json',
            'X-County-Code': this.countryCode,
            'X-Currency-Code': this.currencyCode,
            'X-Language-Code': this.languageCode,
            'X-Client-Uuid': this.clientUid,
          }
        });

        console.log(data);

        let order = null;
        if(data.data) {
          this.order = data.data;
          order = data.data;
        }

        if(!!order){
          const merchantUid = order.seq;
          const IMP = window.IMP;


          const impKey = this.payment.IMP_KEY;
          const impShop = this.payment.IMP_SHOP;

          // console.log(impKey, impShop)
          IMP.agency(impKey, impShop);

          const scriptFncls = window.document.createElement("script");
          scriptFncls.type = 'application/json';
          scriptFncls.setAttribute('fncls', 'fnparams-dede7cc5-15fd-4c75-a9f4-36c430ee3a99');
          scriptFncls.innerHTML = JSON.stringify({"f": `${merchantUid}`,"s": `${this.payment.PAYPAL_ACCOUNT_ID}_checkout-page`,"sandbox": false});
          window.document.body.appendChild(scriptFncls);
          const scriptPaypal = window.document.createElement("script");
          scriptPaypal.type = 'text/javascript';
          scriptPaypal.src = 'https://c.paypal.com/da/r/fb.js';
          window.document.body.appendChild(scriptPaypal);
          const noscript = window.document.createElement("noscript");
          noscript.innerHTML = `<img src="https://c.paypal.com/v1/r/d/b/ns?f=${merchantUid}&s=${this.payment.PAYPAL_ACCOUNT_ID}_checkout-page&js=0&r=1"/>`;
          window.document.body.appendChild(noscript);

          const name = order.items[0].name || '';
          this.requestData = {
            name: name,
            pg: 'paypal_v2.' + this.payment.PAYPAL_ACCOUNT_ID,
            pay_method:'paypal',
            merchant_uid: merchantUid,
            customer_uid: merchantUid,
            escrow: false,
            amount: order.amount,
            currency: order.currency.code,
            buyer_tel: this.checkout.buyer.phone,
            buyer_name: `${this.checkout.buyer.firstName} ${this.checkout.buyer.lastName}`,
            buyer_email: this.checkout.buyer.email,
            niceMobileV2:true,
          }

          IMP.loadUI('paypal-rt', this.requestData, async function(resp) {
            if(resp.error_code){
              alert(resp.error_msg);
              window.location.reload();
              return;
            }
            if(resp.imp_uid){
              const _nuxt = window.$nuxt;
              document.getElementById('checkout-loading').style.display = 'flex';
              const url = process.env.newApiUrl + '/v1/orders/complete';
              const { data } = await axios.put(url, {
                seq: resp.merchant_uid,
                impUid: resp.imp_uid
              });

              if(data.data){
                await _nuxt.$router.push(_nuxt.localePath({path: '/product/order/complete/' + resp.merchant_uid + '?k=' + data.data.password}));
              }
              return;
            }
          });
        }

      } catch (e){
        console.log(e);
      }


    },
    async reFetch() {
      try{

        const country = (this.$store.state.location.code || 'US').toUpperCase();
        const currency = (this.$store.state.currency || 'USD').toUpperCase();
        const language = (this.$i18n.locale || 'en').toLowerCase();

        const requestData = {
          products: this.calculate.products.map((product) => ({
            id: product.id,
            quantity: product.quantity,
          })),
          countryCode: this.checkout.recipient.country,
        };

        //const url = process.env.newApiUrl + '/v1/orders/calculate';
        const url = process.env.newApiUrl + (this.$route.query.snk ? `/v1/orders/calculate-snk?p=${this.$route.query.p}` : '/v1/orders/calculate');

        const {data} = await axios.post(url, requestData, {
          headers: {
            'Content-Type': 'application/json',
            'X-County-Code': country,
            'X-Currency-Code': currency,
            'X-Language-Code': language,
            'X-Client-Uuid': this.$store.state.clientUid || null,
          }
        });

        if (data && data.data) {
          this.calculate = data.data;
        }
      } catch (e){
        console.error(e);
      }
    },
  },
  computed: {
    shopName() {
      if (this.calculate.products.length > 0) {
        return this.calculate.products[0].shop.name;
      }

      return '';
    },

    lnkProduct() {

      if (this.calculate.products.length > 0) {
        return {
          url: this.calculate.products[0].deeplink,
          catalogProductId: this.calculate.products[0].id
        }
      }

      return {
        url: '/',
      }
    },
    lnk() {
      if (this.calculate.products.length > 0) {
        return this.calculate.products[0].deeplink;
      }

      return '/';
    },
    payment() {
      return {
        IMP_KEY: process.env.IMP_KEY,
        IMP_SHOP: process.env.IMP_SHOP,
        PAYPAL_ACCOUNT_ID: process.env.PAYPAL_ACCOUNT_ID,
      }
    },
    inputErrors() {
      return {
        buyer: {
          firstName: this.valid ? !!this.checkout.buyer.firstName : null,
          lastName: this.valid ? !!this.checkout.buyer.lastName : null,
          phone: this.valid ? !!this.checkout.buyer.phone : null,
          email: this.valid ? (!!this.checkout.buyer.email && !!this.checkout.buyer.email.match(emailRegex)) : null,
        },
        recipient: {
          firstName: this.valid ? !!this.checkout.recipient.firstName : null,
          lastName: this.valid ? !!this.checkout.recipient.lastName : null,
          phone: this.valid ? !!this.checkout.recipient.phone : null,
          address1: this.valid ? !!this.checkout.recipient.address1 : null,
          address2: this.valid ? !!this.checkout.recipient.address2 : null,
          postalCode: this.valid ? !!this.checkout.recipient.postalCode : null,
        }
      }
    },
    countries() {
      const c = this.$store.state.countries || [];
      const result = [];

      for(const obj of c){
        if(typeof obj === 'object'){
          for (const key in obj) {
            result.push({ code: key, name: obj[key] });
          }
        }
      }

      return result;
    },
    fullName() {
      const product = this.calculate.products[0];
      return product.catalog.brand.name + ' ' + product.name;
    },
    pageTitle() {
      const product = this.calculate.products[0];
      return this.fullName + ' ' + product.sku.sku;
    },
    pageDescription() {
      return '';
    },
  },
  watch: {
    'checkout.recipient.country'(){
      this.reFetch();
    },
    'syncAddress'(){
      if(this.syncAddress){
        this.checkout.recipient.firstName = this.checkout.buyer.firstName;
        this.checkout.recipient.lastName = this.checkout.buyer.lastName;
        this.checkout.recipient.phone = this.checkout.buyer.phone;
      }
    },
    $route:{
      handler() {
        console.log('ABC', $route.params);
      }
    }
  },
  head(){
    return {
      title: 'Checkout | sneakers123.com',
      meta: [
        {hid: 'description', name: 'description', content: 'Checkout | sneakers123.com'},
        {hid: 'robots', name: 'robots', content: 'noindex'}
      ],
      __dangerouslyDisableSanitizers: ['script']
    }
  }
}
</script>
